<template>
  <v-card
    class="mb-2 my-col pa-0"
    elevation-1
  >
    <v-card-text>
      <v-list class="card-list ma-0 pl-0">
        <v-list-item>
          <vue-skeleton-loader
            type="circle"
            :size="55"
          />
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-medium">
                <vue-skeleton-loader
                  class="ml-4"
                  :width="210"
                  :height="20"
                />
              </div>

              <v-list-item-subtitle class="text-sm mt-2">
                <vue-skeleton-loader
                  class="ml-4"
                  :width="40"
                  :height="20"
                  rounded
                />
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <vue-skeleton-loader
                :width="65"
                :height="20"
              />
              <div class="text-xs text-medium-emphasis float-right mt-4">
                <vue-skeleton-loader
                  :width="30"
                  :height="15"
                />
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

</style>
