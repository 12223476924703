<template>
  <v-menu
    top
    :close-on-click="closeOnClick"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        <span v-if="selected">{{ selected.name }}</span>
        <v-icon
          size="22"
          class="ml-1"
        >
          {{ icons.mdiMenuDown }}
        </v-icon>
      </v-chip>
    </template>

    <v-list>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        @change="updateSelected"
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>

import { mdiMenuDown } from '@mdi/js'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    closeOnClick: true,
    selectedItem: null,
    icons: {
      mdiMenuDown,
    },
  }),
  methods: {
    updateSelected() {
      this.$emit('change', this.items[this.selectedItem])
    },
  },
}
</script>
