<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedDateTime"
        :label="label"
        readonly
        v-on="on"
      />
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            v-if="!showTimePicker"
            cols="12"
          >
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              @input="showTimePicker = true"
            >
              <template v-slot:day="{ date }">
                {{ date.getDate() }}
              </template>
            </v-date-picker>
          </v-col>
          <v-col
            v-if="showTimePicker"
            cols="12"
          >
            <v-time-picker
              v-model="time"
              format="ampm"
              full-width
            >
              <template v-slot:ampm="{ period }">
                {{ period.toUpperCase() }}
              </template>
            </v-time-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false; showTimePicker = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveDateTime"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from 'date-fns'

export default {
  props: {
    label: {
      type: String,
      default: 'Date Time',
    },
  },
  data() {
    return {
      date: null,
      time: null,
      showTimePicker: false,
      dialog: false,
    }
  },
  computed: {
    formattedDateTime() {
      if (this.date && this.time) {
        const dateTime = new Date(this.date)
        const formattedDate = format(dateTime, 'MM/dd/yyyy')
        const formattedTime = format(new Date(`2023-03-16T${this.time}:00`), 'h:mm a')

        return `${formattedDate} ${formattedTime}`
      }

      return null
    },
  },
  watch: {
    date() {
      this.showTimePicker = true
    },
  },
  methods: {
    saveDateTime() {
      this.dialog = false
      this.showTimePicker = false
      this.$emit('change', `${this.date} ${this.time}`)
    },
  },
}
</script>
