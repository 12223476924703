<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Transaction</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="addTransaction()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-radio-group
                    v-model="method"
                    row
                  >
                    <v-radio
                      label="M-Pesa"
                      value="m-pesa"
                    ></v-radio>
                    <v-radio
                      label="Other"
                      value="other"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  v-show="method === 'm-pesa'"
                  cols="12"
                >
                  <v-textarea
                    v-model="form.message"
                    outlined
                    label="M-pesa message"
                    :rules="mpesaRequiredRules"
                    rows="4"
                    row-height="4"
                    placeholder="RCE7SBPO6X Confirmed.You have received Ksh100.00 from John Doe 0700000000 on 14/3/23 at 7:29 PM  New M-PESA balance is Ksh780.34."
                    hint="Copy the M-Pesa message from your phone"
                    persistent-hint
                    required
                  ></v-textarea>
                  <small
                    v-show="form.errors.has('message')"
                    class="validation-error"
                  >{{ form.errors.get('message') }}</small>
                </v-col>
                <v-col
                  v-show="method === 'other'"
                  cols="12"
                >
                  <v-text-field
                    v-model="form.name"
                    outlined
                    label="Name"
                    :rules="otherRequiredRules"
                    placeholder="John Doe"
                    hint="Name of the person or entity that initiated the transaction"
                    persistent-hint
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('name')"
                    class="validation-error"
                  >{{ form.errors.get('name') }}</small>

                  <v-radio-group
                    v-model="form.transaction_type"
                    row
                  >
                    <v-radio
                      label="Money Out"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="Money In"
                      :value="2"
                    ></v-radio>
                  </v-radio-group>
                  <small
                    v-show="form.errors.has('transaction_type')"
                    class="validation-error"
                  >{{ form.errors.get('transaction_type') }}</small>

                  <v-text-field
                    v-model="form.amount"
                    outlined
                    label="Amount"
                    :rules="otherNumberRules"
                    placeholder="e.g 100"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('amount')"
                    class="validation-error"
                  >{{ form.errors.get('amount') }}</small>

                  <div>
                    <p class="text-grey-lighten-4 mb-1">
                      Category
                    </p>
                    <chip-select
                      :items="transactionCategories"
                      :selected="transactionCategory"
                      @change="updateTransactionCategory"
                    />
                    <small
                      v-show="form.errors.has('transaction_category_id')"
                      class="validation-error"
                    >{{ form.errors.get('transaction_category_id') }}</small>
                  </div>

                  <v-expand-transition>
                    <div
                      v-if="transactionSubCategories.length > 0"
                      class="mt-6 mb-6"
                    >
                      <p class="text-grey-lighten-4 mb-1">
                        Sub Category
                      </p>
                      <chip-select
                        :items="transactionSubCategories"
                        :selected="transactionSubCategory"
                        @change="updateTransactionSubCategory"
                      />
                      <small
                        v-show="form.errors.has('transaction_sub_category_id')"
                        class="validation-error"
                      >{{ form.errors.get('transaction_sub_category_id') }}</small>
                    </div>
                  </v-expand-transition>

                  <date-time-picker
                    :label="'Transaction Date'"
                    @close="showDatePicker = false"
                    @change="updateTransactionDate"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import ChipSelect from '../partials/ChipSelect.vue'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  components: {
    ChipSelect,
    DateTimePicker,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      name: '',
      method: 'm-pesa',
      form: new Form({
        name: '',
        amount: '',
        transaction_date: '',
        transaction_type: 1,
        transaction_category_id: '',
        transaction_sub_category_id: '',
      }),
      isRolesLoading: false,
      action: 'Add',
      transactionCategories: [],
      transactionCategory: null,
      transactionSubCategory: null,
    }
  },
  computed: {
    transactionSubCategories() {
      if (!this.transactionCategory) {
        return []
      }
      const matchingCategory = this.transactionCategories.find(category => category.id === this.transactionCategory.id)
      if (!matchingCategory) {
        return []
      }

      return matchingCategory.transaction_sub_categories
    },
    url() {
      return this.method === 'm-pesa' ? 'mpesa-transactions' : 'transactions'
    },
    mpesaRequiredRules() {
      if (this.method === 'm-pesa') {
        return [v => !!v || 'M-PESA number is required']
      }

      return []
    },
    otherRequiredRules() {
      if (this.method === 'other') {
        return [v => !!v || 'This field is required']
      }

      return []
    },
    otherNumberRules() {
      if (this.method === 'other') {
        return [v => !!v || 'This field is required', v => !Number.isNaN(v) || 'Must be a number']
      }

      return []
    },
  },
  mounted() {
    this.getTransactionCategories()
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.method = 'm-pesa'
      this.form.transaction_type = 1
    },
    addTransaction() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post(this.url)
          .then(() => {
            this.$toast.success('Transaction added successfully')
            this.$emit('transactionAdded')
            this.closeDialog()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    getTransactionCategories() {
      axios.get('transaction-categories').then(response => {
        this.transactionCategories = response.data
        this.transactionCategory = response.data[0]
      })
    },
    updateTransactionCategory(category) {
      this.transactionCategory = category
      this.form.transaction_category_id = category.id
    },
    updateTransactionSubCategory(subCategory) {
      this.transactionSubCategory = subCategory
      this.form.transaction_sub_category_id = subCategory.id
    },
    updateTransactionDate(date) {
      this.form.transaction_date = date
    },
  },
}
</script>
