<template>
  <div class="selected-transaction">
    <v-col
      md="12"
      sm="12"
      xs="12"
      class="pb-0"
    >
      <p class="text-grey-lighten-4 mb-1">
        Name
      </p>
      <p class="font-weight-bold">
        {{ transaction.subject }}
      </p>
    </v-col>
    <v-col
      md="12"
      sm="12"
      xs="12"
      class="pb-0"
    >
      <p class="text-grey-lighten-4 mb-1">
        Amount
      </p>
      <p class="font-weight-bold">
        {{ transaction.amount }}
      </p>
    </v-col>
    <v-col
      v-if="transaction.reference_code"
      md="12"
      sm="12"
      xs="12"
      class="pb-0"
    >
      <p class="text-grey-lighten-4 mb-1">
        Reference
      </p>
      <p class="font-weight-bold">
        {{ transaction.reference_code }}
      </p>
    </v-col>
    <v-col
      v-if="transaction.transaction_cost"
      md="12"
      sm="12"
      xs="12"
      class="pb-0"
    >
      <p class="text-grey-lighten-4 mb-1">
        Transaction cost
      </p>
      <p class="font-weight-bold">
        {{ transaction.transaction_cost }}
      </p>
    </v-col>
    <v-col
      v-if="transaction.transaction_category_id"
      md="5"
      sm="12"
      xs="12"
    >
      <p class="text-grey-lighten-4 mb-1">
        Category
      </p>
      <chip-select
        :items="transactionCategories"
        :selected="transactionCategory"
        @change="updateTransactionCategory"
      />
    </v-col>
    <v-expand-transition>
      <v-col
        v-if="transactionSubCategories.length > 0"
        md="5"
        sm="12"
        xs="12"
      >
        <p class="text-grey-lighten-4 mb-1">
          Sub category
        </p>
        <chip-select
          :items="transactionSubCategories"
          :selected="transactionSubCategory"
          @change="updateTransactionSubCategory"
        />
      </v-col>
    </v-expand-transition>
    <div class="mt-2 ml-2">
      <span class="me-2">
        Can't find appropriate category?
      </span>
      <router-link :to="{ name:'settings', query: { tab: 'transaction-category' } }">
        Create one
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ChipSelect from '../partials/ChipSelect.vue'

export default {
  components: {
    ChipSelect,
  },
  props: {
    transaction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      transactionCategory: null,
      transactionSubCategory: null,
      transactionCategories: [],
    }
  },
  computed: {
    transactionSubCategories() {
      if (!this.transactionCategory) {
        return []
      }
      const matchingCategory = this.transactionCategories.find(category => category.id === this.transactionCategory.id)
      if (!matchingCategory) {
        return []
      }

      return matchingCategory.transaction_sub_categories
    },
  },
  watch: {
    transaction() {
      this.setCategoryAndSubCategory()
    },
    transactionCategory() {
      // this.transactionSubCategory = this.transactionCategory.transaction_sub_category
      if (this.transactionCategory) {
        const matchingCategory = this.transactionCategories.find(category => category.id === this.transactionCategory.id)
        if (matchingCategory) {
          this.transactionSubCategory = matchingCategory.transaction_sub_categories.find(subCategory => subCategory.id === this.transactionSubCategory.id)
        }
      } else {
        this.transactionSubCategory = null
      }
    },
  },
  mounted() {
    this.getTransactionCategories()
    this.setCategoryAndSubCategory()
  },
  methods: {
    getTransactionCategories() {
      axios.get('transaction-categories').then(response => {
        this.transactionCategories = response.data
      })
    },
    setCategoryAndSubCategory() {
      this.transactionCategory = this.transaction.transaction_category
      this.transactionSubCategory = this.transaction.transaction_sub_category
    },
    updateTransactionCategory(transactionCategory) {
      this.transactionCategory = transactionCategory
      axios
        .put(`transactions/${this.transaction.id}`, {
          transaction_category_id: transactionCategory.id,
        })
        .then(response => {
          this.$emit('updateTransaction', response.data.transaction)
        })
    },
    updateTransactionSubCategory(transactionSubCategory) {
      this.transactionSubCategory = transactionSubCategory
      axios
        .put(`transactions/${this.transaction.id}`, {
          transaction_sub_category_id: transactionSubCategory.id,
        })
        .then(response => {
          this.$emit('updateTransaction', response.data.transaction)
        })
    },
  },
}
</script>
