<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="150"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="text-none"
          v-on="on"
        >
          <v-icon
            left
            size="16"
          >
            {{ icons.mdiFilter }}
          </v-icon>
          <span class="font-weight-regular">Filter by {{ tableName }}</span>
          <span
            v-if="tableName !== ''"
            class="font-weight-black"
            style="margin-left:3px"
          > from </span>
          <span
            class="font-weight-black"
            style="margin-left:3px"
          > {{ filterLabel }}</span>
          <v-icon
            right
            size="16"
          >
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <date-filter
          v-if="showDateFilter"
          :default-filter="defaultDatePickerDate"
          @date-change="onDateChange"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            @click="onFilterButtonClick"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiFilter,
  mdiChevronDown,
} from '@mdi/js'
import { subDays, subYears, format } from 'date-fns'
import DateFilter from './DateFilter.vue'

export default {
  components: {
    DateFilter,
  },
  props: {
    tableName: {
      type: String,
      default: '',
    },
    showDateFilter: {
      type: Boolean,
      default: true,
    },
    defaultDatePickerDate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      icons: {
        mdiFilter,
        mdiChevronDown,
      },
      filterLabel: 'Last 30 days',
      filters: {},
    }
  },
  watch: {
    defaultDatePickerDate(defaultDatePickerDate) {
      let fromDate = format(subDays(new Date(), 30), 'yyyy-MM-dd')
      let toDate = format(new Date(), 'yyyy-MM-dd')
      if (defaultDatePickerDate === 'Last 1 year') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = format(subYears(new Date(), 1), 'yyyy-MM-dd')
        toDate = format(new Date(), 'yyyy-MM-dd')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    },
  },
  mounted() {
    if (this.showDateFilter) {
      let fromDate
      let toDate

      fromDate = format(subDays(new Date(), 30), 'yyyy-MM-dd')
      toDate = format(new Date(), 'yyyy-MM-dd')

      if (this.defaultDatePickerDate === 'Last 7 days') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = format(subDays(new Date(), 7), 'yyyy-MM-dd')
        toDate = format(new Date(), 'yyyy-MM-dd')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    }
  },
  methods: {
    onDateChange(value) {
      this.filters.dates = value.filters
      this.filterLabel = value.label
    },
    onFilterButtonClick() {
      this.menu = false
      this.$emit('filter', this.filters)
    },
  },

}
</script>
