<template>
  <div>
    <v-row class="mb-8">
      <v-col class="col-md-3">
        <refresh-button
          :loading="isTransactionsRefreshing"
          @click="refreshTransactions()"
        />
      </v-col>
      <v-col class="col-md-6">
        <table-filter
          :table-name="'transactions'"
          :default-date-picker-date="defaultDatePickerDate"
          @filter="applyFilters"
        />
      </v-col>
      <v-col class="col-md-3">
        <v-btn
          class="float-right"
          color="primary"
          @click="showAddTransactionDialog = true"
        >
          Add transaction
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        md="6"
        sm="12"
        xs="12"
      >
        <div v-if="isTransactionsLoading">
          <transaction-card-shimmer
            v-for="n in 7"
            :key="n"
            class="mt-6"
          />
        </div>
        <div v-else>
          <div
            v-for="(transactionData, date) in transactions"
            :key="date"
          >
            <strong class="text-grey-lighten-4">{{ formatDate(date) }}</strong>
            <transaction-card
              v-for="transaction in transactionData"
              :key="transaction.id"
              :transaction="transaction"
              :selected="transaction.id === selectedTransaction.id"
              @pressed="setSelectTransaction(transaction)"
            />
            <br />
          </div>
          <div class="text-center">
            <infinite-loading @infinite="loadMoreTransactions" />
          </div>
        </div>
      </v-col>
      <v-col
        md="6"
        sm="12"
        xs="12"
        d-none
        class="d-none d-md-block"
      >
        <div class="parent">
          <v-card
            class="card-clicked sticky-card mt-6 ml-2"
            elevation="0"
          >
            <v-card-text>
              <div class="text-center">
                <div class="mt-2 mb-2">
                  <strong
                    class="text-grey-lighten-4"
                    style="font-size: 18px;"
                  >Transaction Details</strong>
                </div>
              </div>
            </v-card-text>
            <v-card-text>
              <selected-transaction-card
                v-if="selectedTransaction.id !== null"
                :transaction="selectedTransaction"
                @updateTransaction="updateTransaction"
              />
              <p v-else>
                Select a transaction to view more details
              </p>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <add-transaction-dialog
      :show="showAddTransactionDialog"
      @close="showAddTransactionDialog = false"
      @transactionAdded="refreshTransactions()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { format } from 'date-fns'
import InfiniteLoading from 'vue-infinite-loading'
import TransactionCard from '@/components/details-card/TransactionCard.vue'
import SelectedTransactionCard from '@/components/details-card/SelectedTransactionCard.vue'
import TransactionCardShimmer from '@/components/shimmers/TransactionCardShimmer.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import AddTransactionDialog from '@/components/dialogs/AddTransactionDialog.vue'

export default {
  components: {
    TransactionCard,
    SelectedTransactionCard,
    InfiniteLoading,
    TransactionCardShimmer,
    TableFilter,
    RefreshButton,
    AddTransactionDialog,
  },
  data() {
    return {
      isTransactionsRefreshing: false,
      isTransactionsLoading: false,
      showAddTransactionDialog: false,
      transactions: {},
      page: 2,
      selectedTransaction: { id: null },
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      defaultDatePickerDate: 'Last 30 days',
    }
  },
  mounted() {
    this.getTransactions()
  },
  methods: {
    getTransactions() {
      this.isTransactionsLoading = true
      axios.get(`transactions?fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}`).then(response => {
        this.transactions = response.data.data
      }).finally(() => {
        this.isTransactionsLoading = false
        this.isTransactionsRefreshing = false
      })
    },
    async loadMoreTransactions($state) {
      try {
        const response = await axios.get(`transactions?page=${this.page}&from_date=${this.filters.dates.fromDate}&to_date=${this.filters.dates.toDate}`)
        if (response.data.data.length < 10) $state.complete()
        else {
          this.transactions = { ...this.transactions, ...response.data.data }
          $state.loaded()
        }
        this.page += 1

        return response.data.data
      } catch (error) {
        console.log('🚀 ~ file: transactions.vue:28 ~ loadMoreTransactions ~ error', error)

        return {}
      }
    },
    refreshTransactions() {
      this.isTransactionsRefreshing = true
      this.getTransactions()
    },
    applyFilters(filters) {
      this.filters = filters
      this.getTransactions()
    },
    formatDate(date) {
      return format(new Date(date), 'MMM d, yyyy')
    },
    setSelectTransaction(transaction) {
      this.selectedTransaction = transaction
    },
    updateTransaction(updatedTransaction) {
    // Find the transaction in the transactions object by ID
      let transactionFound = false
      for (const date in this.transactions) {
        const index = this.transactions[date].findIndex(
          transaction => transaction.id === updatedTransaction.id,
        )
        if (index !== -1) {
        // Update the transaction in the transactions object
          this.$set(this.transactions[date], index, updatedTransaction)
          transactionFound = true
          break
        }
      }
      if (!transactionFound) {
        console.log(`Transaction with ID ${updatedTransaction.id} not found in transactions`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 1.5rem;
}
.parent {
  height: 100%;
}
.sticky-card {
  position: fixed;
  min-width: 600px;
}
</style>
