<template>
  <v-card
    :class="{ 'bg-glow': selected }"
    class="mb-2 my-col pa-0"
    elevation-1
    @click="onCardClick"
  >
    <v-card-text>
      <v-list class="card-list ma-0 pl-0">
        <v-list-item>
          <v-avatar
            size="55"
            :color="transactionType == 'credit' ? 'success' : 'warning'"
            class="white--text font-weight-medium me-3"
          >
            {{ nameAbbreviation }}
          </v-avatar>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-medium transaction-subject">
                <span class="text--primary text-base me-1">{{ transaction.subject }}</span>
              </div>

              <v-list-item-subtitle class="text-sm mt-2">
                <v-chip
                  class="me-2"
                >
                  {{ transaction.transaction_category.name }}
                </v-chip>
                <v-chip
                  v-if="transaction.transaction_sub_category"
                  class="me-2"
                >
                  {{ transaction.transaction_sub_category.name }}
                </v-chip>
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
              <h3
                class="font-weight-semibold text-right"
                :style="transactionType == 'credit' ? 'color: #4caf50 !important' : 'color: #ff9800 !important'"
              >
                {{ transactionType == 'credit' ? '+' : '-' }} {{ formatter.format(transaction.amount) }}
              </h3>
              <div class="text-xs text-medium-emphasis text-right mt-4">
                {{ transaction.date | formatTime }}
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { format } from 'date-fns'

export default {
  filters: {
    formatTime(date) {
      return format(new Date(date), 'hh:mm a')
    },
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nameAbbreviation() {
      const nameArray = this.transaction.subject.split(' ')
      let abbreviation = ''
      for (let i = 0; i < nameArray.length; i++) {
        const word = nameArray[i].trim()
        if (word && !word.match(/^(Dr\.|Mr\.|Mrs\.|Ms\.|Miss)$/i)) {
          abbreviation += word.substring(0, 1).toUpperCase()
        }
        if (abbreviation.length >= 2) {
          break
        }
      }

      return abbreviation
    },
    transactionType() {
      const credit = [2]
      if (credit.includes(this.transaction.type)) {
        return 'credit'
      }

      return 'debit'
    },
    formatter() {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES',
        minimumFractionDigits: 0,
      })
    },
  },
  methods: {
    onCardClick() {
      this.$emit('pressed', this.transaction)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 1.5rem;
}
.parent {
  height: 100%;
}
.sticky-card {
  position: fixed;
  min-width: 600px;
}
.transaction-subject {
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bg-glow {
  box-shadow: 0 0 10px 2px #bbbcbd !important;
}
</style>
